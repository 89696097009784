import React from 'react';
import styled from 'styled-components';

import { SiteLayout } from '../../components/Layout/SiteLayout';
import {
  FirstScreen,
  IntegrationScreen,
  CompressionScreen,
  InfrastructureScreen,
} from '../../components/forImageCDN';
import { FinalCTA } from '../../components/FinalCTA';
import { ClientsCases } from '../../components/ClientsCases';
import { frontmatter as originalFrontmatter } from '../demo';

const ImageProcessingClientsCases = styled(ClientsCases)`
  margin-top: 0;
`;

export const frontmatter = {
  title: 'Image CDN and image optimization for web | Uploadcare',
  description:
    'Uploadcare Image CDN with an intuitive URL API provides multiple chainable operations to compress and optimize an image on the fly right before it is delivered to your users.',
  canonicalUrl: 'https://uploadcare.com/cdn/image-cdn/',
  noindex: true,
};

const ImageCdn = ({ location }) => (
  <SiteLayout
    meta={{
      ...originalFrontmatter,
      ...frontmatter,
    }}
    pathName={location.pathname}
  >
    <FirstScreen ppc />
    <CompressionScreen ppc />
    <IntegrationScreen ppc />
    <InfrastructureScreen ppc />
    <ImageProcessingClientsCases ppc />
    <FinalCTA heading="Start for free, grow for as low as $20" ppc />
  </SiteLayout>
);

export default ImageCdn;
